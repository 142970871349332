/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const regexCausale = /^[\w!@#$%^&*()+\-=\[\]{};':"\\| ,.<>\/?]*$/;
export const regexCodiceFiscaleIva = /^(?:([A-Z]{6}[0-9A-Z]{2}[A-Z][0-9A-Z]{2}[A-Z][0-9A-Z]{3}[A-Z])|([0-9]{11})|STRANIERO)$/;

export const eRequired = "Campo obbligatorio";
export const eCausaleReg = "La causale contiene dei caratteri non consentiti.";
export const eCausaleLen = "La causale ammette un massimo di 140 caratteri.";
export const eComuneLen = "Il comune può contenere un massimo di 35 caratteri.";
export const eIndirizzoLen = "L'indirizzo può contenere un massimo di 70 caratteri.";
export const eNoteVersanteLen = "Le note ammettono un massimo di 2000 caratteri.";
export const eCodiceFiscaleIvaReg = "Codice fiscale o partita IVA non valido.";
export const eNomeLen = "Il nome non può superare i 50 caratteri.";
export const eCognomeLen = "Il cognome non può superare i 50 caratteri.";
export const eEmailVal = "Inserire un indirizzo email valido.";

export const vCdr = yup.string().default(null).required("Scegliere la struttura organizzativa di riferimento");
export const vImporto = yup.number().required(eRequired).min(0.01, "L'importo deve essere maggiore di 0.").default(0.01);
export const vCausale = yup.string().required(eRequired).matches(regexCausale, eCausaleReg).max(140, eCausaleLen);
export const vComune = yup.string().required(eRequired).max(35, eComuneLen);
export const vIndirizzo = yup.string().required(eRequired).max(70, eIndirizzoLen);
export const vNoteVersante = yup.string().max(2000, eNoteVersanteLen);
export const vEmail = yup.string("Campo obbligatorio.").required(eRequired).email(eEmailVal);
export const vPrivacy = yup.boolean().oneOf([true], "Per procedere è necessario selezionare la casella soprastante.");

export const vCfIvaStraniero = yup.string().required(eRequired).default("");
export const vCfIva = yup.string().required(eRequired).matches(regexCodiceFiscaleIva, eCodiceFiscaleIvaReg).default("");
export const vNome = yup.string().required(eRequired).max(50, eNomeLen);
export const vCognome = yup.string().required(eRequired).max(50, eCognomeLen);

export const vDebitore = yup.object({
	cfIva: vCfIvaStraniero.when("straniero", { is: (s) => !s, then: vCfIva }),
	nome: vNome,
	cognome: vCognome
}).default({ });

export const defaultPaymentFormValidator = yup.object({
	importo: vImporto,
	causale: vCausale,
	debitore: vDebitore,
	comune: vComune,
	indirizzo: vIndirizzo,
	noteVersante: vNoteVersante
});

export const paymentValidationSet = yup.object({
	versante: yup.object({
		email: vEmail,
		cfIva: vCfIva
	})
});

export const paymentProcessValidation = yup.object({
	cfIva: vCfIva,
	email: vEmail.nullable()
});
